import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { languages } from "../languages";

import store from "../store";

Vue.use(VueRouter);

const routes = [
	{
		path: "/:language?",
		name: "Home",
		component: Home,
	},
];

const router = new VueRouter({
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	const languageExists = languages[to.params.language] ? true : false;
	const localStorageLang = localStorage.getItem("language");

	if (to.params.language && languageExists) {
		if (localStorageLang) {
			store.commit("setLanguage", localStorageLang);
		} else {
			store.commit("setLanguage", to.params.language);
			localStorage.setItem("language", to.params.language);
		}

		next();
	} else {
		if (localStorageLang) {
			store.commit("setLanguage", localStorageLang);
			return next();
		}
		// set default language

		//pt_br
		// store.commit("setLanguage", "pt_br");
		// localStorage.setItem("language", "pt_br");

		//en_us
		// store.commit("setLanguage", "en_us");
		// localStorage.setItem("language", "en_us");

		//es_ag
		// store.commit("setLanguage", "es_ag");
		// localStorage.setItem("language", "es_ag");

		//es_ec
		// store.commit("setLanguage", "es_ec");
		// localStorage.setItem("language", "es_ec");

		//es_pe
		// store.commit("setLanguage", "es_pe");
		// localStorage.setItem("language", "es_pe");

		//es_mx
		// store.commit("setLanguage", "es_mx");
		// localStorage.setItem("language", "es_mx");

		//es_col
		// store.commit("setLanguage", "es_col");
		// localStorage.setItem("language", "es_col");

		//es_ch
		// store.commit("setLanguage", "es_ch");
		// localStorage.setItem("language", "es_ch");

		//es_up
		store.commit("setLanguage", "es_up");
		localStorage.setItem("language", "es_up");

		next();
	}
});

export default router;
