<template>
	<nav class="mainNav linha" :class="{ greenBg: isGreen }">
		<div class="container">
			<div class="handleMenu" @click="handleMenu" :class="{ green: isGreen }">
				<svg xmlns="http://www.w3.org/2000/svg" width="123.528" height="123.528" viewBox="0 0 123.528 123.528">
					<g id="Grupo_165" data-name="Grupo 165" transform="translate(68.355 2.52)">
						<path
							id="Caminho_606"
							data-name="Caminho 606"
							d="M-4.681,11.258,72.86,4.152a5.617,5.617,0,0,1,5.6,5.632L72.615,84.472a5.617,5.617,0,0,1-5.6,5.632L6.837,78.463a5.617,5.617,0,0,1-5.6-5.632L-10.282,16.89A5.617,5.617,0,0,1-4.681,11.258Z"
							transform="translate(2.629 1.814) rotate(45)"
							fill="#fbfbfb"
						/>
						<path
							id="Sub_1"
							data-name="Subtração 1"
							d="M7638.9,20024.947H7620v-1.543h18.9v1.543Zm-7.428-6.7H7620v-1.549h11.473v1.549Zm7.428-6.705H7620V20010h18.9v1.543Z"
							transform="translate(-7600 -19957)"
							fill="#009639"
							stroke="rgba(0,0,0,0)"
							stroke-width="1"
						/>
					</g>
				</svg>
			</div>
			<h1>
				<a href="#top" v-smooth-scroll>
					<img src="../assets/images/logo-verde@2x.png" alt="Kelly Tires" :class="{ green: isGreen }" />
				</a>
			</h1>
			<span class="btnContato" v-if="languageStrings[language].contact_link"
				><a :href="languageStrings[language].contact_link" target="_blank" v-smooth-scroll>{{ languageStrings[language].menu.contact }}</a></span
			>
			<span class="btnContato" v-else
				><a href="#contato" v-smooth-scroll>{{ languageStrings[language].menu.contact }}</a></span
			>
			<ul class="desk" :class="{ isSpanish: language !== 'pt_br' }">
				<li>
					<a href="#pneus" v-smooth-scroll>{{ languageStrings[language].menu.tires }}</a>
				</li>
				<li>
					<a href="#quemSomos" v-smooth-scroll>{{ languageStrings[language].menu.about }}</a>
				</li>
				<li>
					<a :href="languageStrings[language].where_to_buy_link" target="_blank">{{ languageStrings[language].menu.where_to_buy }}</a>
				</li>
				<li class="btnContato" v-if="languageStrings[language].contact_link">
					<a :href="languageStrings[language].contact_link" target="_blank">{{ languageStrings[language].menu.contact }}</a>
				</li>
				<li class="btnContato" v-else>
					<a href="#contato" v-smooth-scroll>{{ languageStrings[language].menu.contact }}</a>
				</li>
			</ul>
			<div class="menuMobileOpen" :class="{ open: isOpen }">
				<div class="topMenu">
					<span class="close" @click="handleMenu"><font-awesome-icon :icon="['fas', 'times']"/></span>
					<span class="logo">
						<a href="#top" v-smooth-scroll @click="handleMenu">
							<img src="../assets/images/logo-branco@2x.png" alt="Kelly Tires" />
						</a>
					</span>

					<span class="btnContato" v-if="languageStrings[language].contact_link"
						><a :href="languageStrings[language].contact_link" target="_blank">{{ languageStrings[language].menu.contact }}</a></span
					>
					<span class="btnContato" v-else>
						<a href="#contato" v-smooth-scroll @click="handleMenu">{{ languageStrings[language].menu.contact }}</a></span
					>

					<!-- <div class="languages mob" :class="{ greenBg: isGreen }">
						<a :class="{ selected: language === 'en_us' }" href="#" @click.prevent="changeLanguage('en_us')"> English <img src="../assets/images/eng.png" alt="" /> </a>
						<a :class="{ selected: language === 'es_cam' }" href="#" @click.prevent="changeLanguage('es_cam')"> Español <img src="../assets/images/es.png" alt="" /> </a>
					</div> -->
				</div>
				<ul>
					<li>
						<a href="#pneus" v-smooth-scroll @click="handleMenu">{{ languageStrings[language].menu.tires }}</a>
					</li>
					<li>
						<a href="#quemSomos" v-smooth-scroll @click="handleMenu">{{ languageStrings[language].menu.about }}</a>
					</li>
					<li>
						<a :href="languageStrings[language].where_to_buy_link" target="_blank" @click="handleMenu">{{ languageStrings[language].menu.where_to_buy }}</a>
					</li>
					<!-- <li v-if="languageStrings[language].contact_link">
						<a :href="languageStrings[language].contact_link" target="_blank">{{ languageStrings[language].menu.contact }}</a>
					</li>
					<li v-else>
						<a href="#contato" v-smooth-scroll @click="handleMenu">{{ languageStrings[language].menu.contact }}</a>
					</li> -->
				</ul>
				<img src="../assets/images/ilustra.png" alt="" aria-hidden="true" class="ilustra" />
				<div class="footer linha">
					<div class="container">
						<span class="cprt">{{ languageStrings[language].footerSection.copyright }}</span>
						<a href="#" class="termos">{{ languageStrings[language].menu.terms }}</a>
						<span class="acompanhe">{{ languageStrings[language].menu.follow }}</span>
						<div class="social" v-if="language !== 'es_up'">
							<a v-if="languageStrings[language].footerSection.links.linkedin" :href="languageStrings[language].footerSection.links.linkedin" title="LinkedIn" class="twitter icon"
								><font-awesome-icon :icon="['fab', 'linkedin-in']"
							/></a>

							<a v-if="languageStrings[language].footerSection.links.instagram" :href="languageStrings[language].footerSection.links.instagram" title="Instagram" class="insta icon"
								><font-awesome-icon :icon="['fab', 'instagram']"
							/></a>

							<a v-if="languageStrings[language].footerSection.links.facebook" :href="languageStrings[language].footerSection.links.facebook" title="Facebook" class="fb icon"
								><font-awesome-icon :icon="['fab', 'facebook-f']"
							/></a>

							<a v-if="languageStrings[language].footerSection.links.twitter" :href="languageStrings[language].footerSection.links.twitter" title="Twitter" class="fb icon" target="_blank"
								><font-awesome-icon :icon="['fab', 'twitter']"
							/></a>
						</div>

						<div class="social" v-else>
							<div class="up ur">
								<span>
									<img src="../assets/images/uy.png" alt="" />
								</span>
								<a v-if="languageStrings[language].footerSection.links_ur.linkedin" :href="languageStrings[language].footerSection.links_ur.linkedin" title="LinkedIn" class="twitter icon"
									><font-awesome-icon :icon="['fab', 'linkedin-in']"
								/></a>

								<a v-if="languageStrings[language].footerSection.links_ur.instagram" :href="languageStrings[language].footerSection.links_ur.instagram" title="Instagram" class="insta icon"
									><font-awesome-icon :icon="['fab', 'instagram']"
								/></a>

								<a v-if="languageStrings[language].footerSection.links_ur.facebook" :href="languageStrings[language].footerSection.links_ur.facebook" title="Facebook" class="fb icon"
									><font-awesome-icon :icon="['fab', 'facebook-f']"
								/></a>

								<a v-if="languageStrings[language].footerSection.links_ur.twitter" :href="languageStrings[language].footerSection.links_ur.twitter" title="Twitter" class="fb icon" target="_blank"
									><font-awesome-icon :icon="['fab', 'twitter']"
								/></a>
							</div>
							<div class="up py">
								<span>
									<img src="../assets/images/py.png" alt="" />
								</span>
								<a v-if="languageStrings[language].footerSection.links_py.linkedin" :href="languageStrings[language].footerSection.links_py.linkedin" title="LinkedIn" class="twitter icon"
									><font-awesome-icon :icon="['fab', 'linkedin-in']"
								/></a>

								<a v-if="languageStrings[language].footerSection.links_py.instagram" :href="languageStrings[language].footerSection.links_py.instagram" title="Instagram" class="insta icon"
									><font-awesome-icon :icon="['fab', 'instagram']"
								/></a>

								<a v-if="languageStrings[language].footerSection.links_py.facebook" :href="languageStrings[language].footerSection.links_py.facebook" title="Facebook" class="fb icon"
									><font-awesome-icon :icon="['fab', 'facebook-f']"
								/></a>

								<a v-if="languageStrings[language].footerSection.links_py.twitter" :href="languageStrings[language].footerSection.links_py.twitter" title="Twitter" class="fb icon" target="_blank"
									><font-awesome-icon :icon="['fab', 'twitter']"
								/></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="languages" :class="{ greenBg: isGreen }">
				<a :class="{ selected: language === 'en_us' }" href="#" @click.prevent="changeLanguage('en_us')"> English <img src="../assets/images/eng.png" alt="" /> </a>
				<a :class="{ selected: language === 'es_cam' }" href="#" @click.prevent="changeLanguage('es_cam')"> Español <img src="../assets/images/es.png" alt="" /> </a>
			</div> -->
		</div>
	</nav>
</template>

<script>
	import _ from "lodash";
	import logosvg from "@/components/logoSVG.vue";
	import { languages } from "../languages";
	export default {
		name: "MainNav",
		data() {
			return {
				//language: "",
				languageStrings: languages,
				isGreen: false,
				isOpen: false,
			};
		},
		components: {
			logosvg,
		},
		methods: {
			setMenuGreen: _.debounce(function() {
				//console.log(window.pageYOffset)
				if (window.pageYOffset > 150) {
					this.isGreen = true;
				} else {
					this.isGreen = false;
				}
			}, 100),
			handleMenu() {
				this.isOpen = !this.isOpen;
			},
			changeLanguage(lang) {
				this.$store.commit("setLanguage", lang);
				localStorage.setItem("language", lang);
			},
		},
		computed: {
			language() {
				return this.$store.state.language;
			},
		},
		created() {
			window.addEventListener("scroll", this.setMenuGreen);
			//this.language = localStorage.getItem("language");
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "../assets/sass/main.scss";
	.mainNav {
		padding: 20px 5%;
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		width: 100%;
		height: 70px;
		transition: 0.3s ease;
		background: $branco;
		@media #{$mobile} {
			background: transparent;
		}
		&.greenBg {
			background: $defGreen;
			@media #{$mobile} {
				background: transparent;
			}
			a:not(.btnContato) {
				&:before {
					background: $branco !important;
				}
			}
			ul.desk li a {
				color: $branco !important;
			}
			ul.desk .btnContato a {
				color: $defGreen !important;
				background: $branco;
			}
		}
		@media #{$mobile} {
			position: relative;
			margin-bottom: -70px;
		}
		.languages {
			display: flex;
			//display: none;
			justify-content: space-between;
			a {
				display: flex;
				align-items: center;
				text-decoration: none;
				color: #9b9b9b;
				transition: 0.3s;
				&:hover,
				&.selected {
					color: $defGreen;
				}
				img {
					margin-left: 10px;
				}
				&:first-child {
					margin: 0 10px;
				}
			}
			&.greenBg {
				a {
					color: $branco;
					&:hover {
						opacity: 0.5;
						color: $branco;
					}
					&.selected {
						color: $branco;
						text-decoration: underline;
					}
				}
			}
			&.desk {
				@media #{$mobile} {
					display: none;
				}
			}
			@media #{$mobile} {
				width: 35%;
				flex-wrap: wrap;
				justify-content: flex-end;
				align-items: flex-end;
				text-align: right;
				margin: 6px 0 0;
				transform: scale(0.8);
				a {
					justify-content: flex-end;
					width: 100%;
					color: $branco;
					margin: 0 0 13px;
					&:first-child {
						margin: 0 0 13px;
					}
					&.selected {
						color: $branco;
						text-decoration: underline;
					}
				}
			}
			&.mob {
				width: 50%;
				flex-wrap: wrap;
				justify-content: flex-end;
				align-items: flex-end;
				text-align: right;
				margin-top: -15px;
				a {
					justify-content: flex-end;
					width: 100%;
					color: $branco;
					margin: 0 0 10px;
					&:first-child {
						margin: 0 0 10px;
					}
					&.selected {
						color: $branco;
						text-decoration: underline;
					}
				}
			}
		}
		.container {
			display: flex;
		}
		.handleMenu {
			display: none;
			@media #{$mobile} {
				display: block;
				position: fixed;
				top: 0;
				left: -17%;
				height: 105px;
				transition: 0.3s ease;
				img,
				svg {
					height: 100%;
				}
				path {
					transition: 0.3s ease;
				}
			}
			&.green {
				left: -22%;
				#Caminho_606 {
					fill: $defGreen;
				}
				#Sub_1 {
					fill: #fff;
				}
			}
		}
		h1 {
			margin-right: auto;
			width: 16%;
			position: relative;
			z-index: 999;
			@media #{$mobile} {
				width: 48%;
				top: 22px;
				left: 30px;
			}
			img {
				filter: brightness(1) invert(0);
				&.green {
					filter: brightness(0) invert(1);
				}
				@media #{$mobile} {
					filter: brightness(0) invert(1);
				}
			}
		}
		span {
			&.btnContato {
				display: none;
				position: relative;
				top: 22px;
				right: -20px;
				@media #{$mobile} {
					//display: none;
					display: block;
					white-space: nowrap;
				}
				a {
					background: $branco;
					color: $defGreen;
					padding: 8px 20px;
					transition: 0.3s;
					text-decoration: none;
					&:hover {
						opacity: 0.6;
					}

					@media #{$mobile} {
						color: $branco !important;
						background: $defGreen;
						padding: 8px 8px;
						font-size: 0.9em;
						margin: 0 0 0 20px;
					}
				}
			}
		}

		.menuMobileOpen {
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: -100%;
			z-index: 9999;
			background: $defGreen;
			transition: 0.3s ease;
			&.open {
				left: 0;
			}
			.topMenu {
				padding: 50px 15px 20px 15px;
				display: flex;
				justify-content: space-between;
				.close {
					color: $branco;
					font-size: 1.3rem;
					margin: 3px 15px 0 0;
				}
				.btnContato {
					top: auto;
					right: auto;
					margin-top: 2px;
				}
				.logo {
					width: 50%;
				}
			}
			ul {
				text-align: center;
				li {
					padding: 15px;
					a {
						display: block;
						color: $branco;
						@include fontSize(20px);
						text-decoration: none;
					}
				}
			}
			.ilustra {
				display: block;
			}
		}
		ul.desk {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			width: 60%;
			height: 100%;
			@media #{$mobile} {
				display: none;
			}
			li {
				font-weight: 700;
				a {
					color: #9b9b9b;
					text-decoration: none;
					display: block;
					padding: 10px 12px;
				}
				&:not(.btnContato) {
					a {
						position: relative;
						&:before {
							content: "";
							width: 0;
							height: 2px;
							position: absolute;
							top: 103%;
							left: 0px;
							transition: 0.3s ease;
							background: $defGreen;
						}
						&:hover {
							&:before {
								width: 100%;
							}
						}
					}
				}
				&.btnContato {
					a {
						background: $defGreen;
						color: $branco;
						padding: 10px 40px;
						transition: 0.3s;
						&:hover {
							opacity: 0.6;
						}
					}
				}
			}

			&.isSpanish {
				li {
					&.btnContato {
						a {
							padding: 10px 13px;
						}
					}
					&:not(.btnContato) {
						a {
							padding: 10px 5px;
						}
					}
				}
			}
		}
	}
	.footer {
		margin-top: -20vh;
		padding: 0 0 70px;
		color: $branco;
		.container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			@media #{$mobile} {
				justify-content: center;
				&:before {
					content: "";
					display: block;
					width: 100%;
					order: 6;
				}
			}
		}
		.social {
			flex-wrap: wrap;
			@media #{$mobile} {
				order: 3;
				width: 100%;
				display: flex;
				justify-content: center;
			}
			.up {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				span {
					margin-right: 15px;
				}
			}
		}
		.cprt {
			margin: 0 30px;
			@media #{$mobile} {
				order: 4;
				width: 100%;
				margin: 0;
				text-align: center;
			}
		}
		span,
		.termos {
			font-size: 0.8rem;
		}
		.termos {
			color: $branco;
			text-decoration: none;
			margin-right: 40px;
			&:hover {
				text-decoration: underline;
			}
			@media #{$mobile} {
				order: 5;
				width: 50%;
				text-align: center;
				margin-top: 20px;
				margin-right: 0;
			}
		}
		.acompanhe {
			@media #{$mobile} {
				order: 2;
				width: 50%;
				text-align: center;
			}
		}
		.twitter {
			@media #{$mobile} {
				margin-left: 0px;
			}
		}
		/* .insta {
			margin: 0 15px;
			@media #{$mobile} {
				margin: 0 25px;
			}
		} */
		.icon {
			color: $branco;
			font-size: 1.45rem;
			transition: 0.3s;
			margin: 0 7px;
			&:hover {
				opacity: 0.6;
			}
			@media #{$mobile} {
				margin-top: 15px;
				margin-bottom: 15px;
				font-size: 1.8rem;
			}
		}
	}
</style>
