export const es_cam = {
	menu: {
		contact: "Habla con nosotros",
		tires: "Llantas",
		about: "Quienes Somos",
		where_to_buy: "¿Dónde comprar?",
		terms: "Términos de Uso y Política de Privacidad",
		follow: "Sigue a Goodyear",
	},
	fhb: {
		backsplashAltText: "Kelly Tires | Um bom negócio por um ótimo pneu.",
		title: {
			one: "Un",
			bgGreen_1: "buen negocio",
			for: "por una",
			bgGreen_2: "gran llanta.",
		},
		subtitle: "La opción que necesitas y el beneficio que deseas para tu flota",
	},
	tiresSection: {
		domenicanDisclaimer: "No aplica para República Dominicana",
		title: "Llantas",
		subtitle: "Descubra la línea de llantas Kelly para camiones y autobuses",
		description: "Una selección completa de llantas para camiones y autobuses con una gran relación costo-beneficio para su flota.",
		readMore: "Aprende Más",
		tireTypes: {
			MSAII: {
				name: "Armorsteel Kelly MSA II",
				description: "Para servicio mixto, en ejes direccionales, de tracción libre y moderada.",
				link: "https://commercial.goodyearca.com/es/detalles-llantas?ph=02010453147",
			},
			MSDII: {
				name: "Armorsteel Kelly MSD II",
				description: "Para servicio mixto, en ejes de tracción",
				link: "https://commercial.goodyearca.com/es/detalles-llantas?ph=02010453148",
			},
			KS461: {
				name: "Armorsteel Kelly KS461",
				description: "Llanta versátil en servicio regional y urbano, para ejes direccionales, de tracción libre y moderada.",
				link: "https://commercial.goodyearca.com/es/detalles-llantas?ph=02010450667",
			},
			KS481: {
				name: "Armorsteel Kelly KS481",
				description: "Llanta versátil en servicio regional y urbano, para ejes de tracción.",
				link: "https://commercial.goodyearca.com/es/detalles-llantas?ph=02010450668",
			},
		},
	},
	bannersAltText: {
		first: "servicio mixto",
		second: "servicio regional",
	},
	aboutSection: {
		title: "Quienes Somos",
		subtitle: "Las Llantas Kelly",
		text: `<p>
		Cuando se trata de llantas, nadie ha estado en el mercado durante tanto tiempo como Kelly Tires. Nuestro legado comenzó en 1894, en Springfield, Ohio, Estados Unidos, con la fabricación de ruedas de caucho macizo para buggies y carruajes. Desde entonces, hemos crecido y ahora ofrecemos una <b>línea completa de llantas para automóviles, camiones</b> y aplicaciones comerciales. Nuestro lema "Un buen negocio por una gran llanta" es tan relevante hoy como lo fue hace más de 120 años.
      </p>

	  <p>
	  
	  Con Kelly, puede encontrar <b>llantas de calidad diseñadas y producidas en las Américas</b>, con un valor excelente para nuestros clientes. Esta filosofía fue la que llevó a la empresa Goodyear Tire & Rubber a comprar Kelly Tyres en 1935, y estamos orgullosos de seguir siendo una subsidiaria de propiedad absoluta de este líder mundial. Las llantas Kelly son fabricadas por Goodyear en Brasil y Estados Unidos y están disponibles a través de la red de distribuidores oficiales de Goodyear. Consulte los términos de la garantía
        <a
          href="https://trucktires.goodyearca.com/es/garantia-de-llantas"
          target="_blank"
          >aquí</a
        >.
      </p>`,
	},
	newsletter: {
		title: "Assine a nossa Newsletter",
		labels: {
			name: "Nome",
			email: "E-mail",
		},
		legalText: `Ao clicar, você aceita os termos da
        <a
          href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade"
          target="_blank"
          >política de privacidade</a
        >, bem como receber comunicações da Goodyear e suas marcas
        associadas.`,
	},
	contactForm: {
		buttons: {
			send: "enviar",
			cancel: "cancelar",
		},
		labels: {
			firstName: "Nome",
			lastName: "Sobrenome",
			email: "E-mail",
			company: "Empresa",
			address: "Endereço",
			address_2: "Complemento",
			state: "Estado",
			city: "Cidade",
			zipCode: "CEP",
			phone: "Telefone",
			message: "Mensagem",
		},
		checks: {
			cookies: `Li e estou de acordo com a <a href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade" target="_blank">política de cookies</a>, assim como as
            <a href="https://pneuscaminhao.goodyear.com.br/politica-de-privacidade" target="_blank">políticas de privacidade</a>.`,
			promo: "Aceito receber comunicações e promoções da Goodyear e suas marcas associadas.",
		},
	},
	formSection: {
		title: "Habla con nosotros",
		desc_1: "Estamos muy orgullosos de nuestros productos y nuestra relación con clientes especiales como usted.",
		desc_2: "Comuníquese con nosotros si tiene alguna pregunta o inquietud sobre:",
		tireIcon: "Consulta sobre producto",
		atendantIcon: "Asistencia en garantía",
		tagIcon: "Información sobre promoción",
		consultas: "Consultas Generales",
	},
	footerSection: {
		copyright: "© 2020 Kelly Tires",
		terms_conditions: "Términos de Uso y Política de Privacidad",
		follow_us: "Sigue a Goodyear",
		links: {
			linkedin: "",
			facebook: "https://www.facebook.com/goodyearcamcar",
			instagram: "",
			twitter: "",
		},
	},
	cookieMessage: "",
	terms_link: "https://commercial.goodyearca.com/es/terminos-uso-politica-privacidad",
	contact_link: "https://commercial.goodyearca.com/es/Comunicate-con-Nosotros",
	where_to_buy_link: "https://comercial.goodyearca.com/es/encuentre-distribuidor",
};
